import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { tap } from  'rxjs/operators';
import { Observable, BehaviorSubject } from  'rxjs';

import { Storage } from  '@ionic/storage';
import { User } from  './user';
import { ISignUp } from  './isign-up';
import { AuthResponse } from  './auth-response';

const TOKEN_KEY = "auth-token";
//const TOKEN_KEY_EXPIRES_IN = "auth-token-expires-in";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  /*
    We use our BehaviorSubject to tell everyone that the user is now authenticated.
    Other pages can subscribe to this authenticationState or check if the user is authenticated using the current value of the Subject.
  */
  authSubject = new BehaviorSubject(false);

  user: User;

  constructor(private  httpClient:  HttpClient, private  storage:  Storage, private plt: Platform) {
    // cuando el servicio/aplicacion/plataforma este disponible, chequeamos el token
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  /*
    Also, we have added a check to the constructor so we look for a stored token once the app starts. 
    By doing this, we can automatically change the authentication state if the user was previously logged in. 
    In a real scenario you could add an expired check here.
  */
  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.storage.get('USER').then(user => {
          if (user) {
            this.user = user;
            this.authSubject.next(true);
          }
        })
      }
    })
  }

  register(user: User): Observable<ISignUp> {
    return this.httpClient.post<ISignUp>('/register', user).pipe(
      tap(async (res:  ISignUp ) => {

      })
    );
  }

  login(user: User): Observable<AuthResponse> {
    return this.httpClient.post('/login', user).pipe(
      tap(async (res: AuthResponse) => {
        if (res.token) {
          await this.storage.set(TOKEN_KEY, res.token);
          await this.storage.set('USER', res.user);
          this.authSubject.next(true);
          this.user = res.user;
        }
      })
    );
  }

  async logout() {
    await this.storage.remove(TOKEN_KEY);
    this.authSubject.next(false);
  }

  isLoggedIn() {
    return this.authSubject.asObservable();
  }

  isAuthenticated() {
    return this.authSubject.value;
  }

}
