import { Component, Optional, ViewChildren, QueryList } from '@angular/core';
import { Platform, IonRouterOutlet, ToastController, ModalController, ActionSheetController, PopoverController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})


/* get a reference to the used IonRouterOutlet 
assuming this code is placed in the component
that hosts the main router outlet, probably app.components */
//@ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;
export class AppComponent {
  // set up hardware back button event.
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    @Optional() private routerOutlet: IonRouterOutlet,
    private platform: Platform,
//    private splashScreen: SplashScreen,
//    private statusBar: StatusBar,
    private authenticationService: AuthService,
    private router: Router,
    private modalCtrl: ModalController,
    private menu: MenuController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private toastController: ToastController
  ) {
    this.initializeApp();

    // Initialize BackButton Eevent.
    this.backButtonEvent();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      // Now the app will automatically change pages on login or logout, 
      // and we don’t need to navigate from the single pages
      // as all logic is already available at this place.
      this.authenticationService.authSubject.subscribe(state => {
        if (state) {
          this.router.navigate(['members', 'menu']);
        } else {
          this.router.navigate(['login']);
        }
      });
    });
  }

  // active hardware back button
  backButtonEvent() {
      this.platform.backButton.subscribe(async () => {
          // close action sheet
          try {
              const element = await this.actionSheetCtrl.getTop();
              if (element) {
                  element.dismiss();
                  return;
              }
          } catch (error) {
          }

          // close popover
          try {
              const element = await this.popoverCtrl.getTop();
              if (element) {
                  element.dismiss();
                  return;
              }
          } catch (error) {
          }

          // close modal
          try {
              const element = await this.modalCtrl.getTop();
              if (element) {
                  element.dismiss();
                  return;
              }
          } catch (error) {
          }

          // close side menua
          try {
              const element = await this.menu.getOpen();
              if (element) {
                  this.menu.close();
                  return;

              }

          } catch (error) {

          }

          this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
              if (outlet && outlet.canGoBack() && (
                  this.router.url !== '/login' && 
                  this.router.url !== '/members/menu/employee-landing' && 
                  this.router.url !== '/members/menu/employee-landing-gang-responsible' && 
                  this.router.url !== '/members/menu/employee-landing-gang-member' && 
                  this.router.url !== '/register')
                ) {
                  let toast = this.toastController.create({
                    header: "Back accepted",
                    message: this.router.url,
                    duration: 1000,
                    position: 'middle',
                  }).then((toast)=>{
                    toast.present();
                  });

                  outlet.pop();
              } else if (this.router.url === '/login' || this.router.url === '/members/menu/employee-landing' || this.router.url === '/members/menu/employee-landing-gang-responsible' || this.router.url === '/members/menu/employee-landing-gang-member' || this.router.url === '/register') {
                navigator['app'].exitApp(); // work in ionic 4
              }
          });
      });
  }
}
