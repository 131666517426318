import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

/*
All the pages use lazy loading therefore we are using the files plus a hash and the name of the module.

For the last route with the auth guard we are also not loading the real module but another routing,
the routing for the members area. We’ve created this additional file with the Angular CLI and inside
we only need to reference the home
*/

const routes: Routes = [
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './auth/login/login.module#LoginPageModule'},
  { path: 'register', loadChildren: './auth/register/register.module#RegisterPageModule'},
  { path: 'members', loadChildren: './members/member-routing/member-routing.module#MemberRoutingModule', canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes/*, { preloadingStrategy: PreloadAllModules }*/)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
