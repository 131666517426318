import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

/*
To protect our pages we will later use the Angular Router and a check to see if a user is allowed to access a route.
With Angular we use the Auth Guards and therefore create our own Guard where we check our service for the current state of the user.
The guard implements only this one function in which we use the previous service 

The top routing allows to navigate to the register and login page without any checks, 
but behind the members path every pages will go through the canActivate check so they can only be access once a user is authenticated!
*/
export class AuthGuard implements CanActivate {

  constructor(public auth: AuthService) {}
 
  canActivate(): boolean {
    return this.auth.isAuthenticated();
  }
}
